<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <table v-else class="min-w-full table-fixed divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="min-w-[16rem] py-3.5 sticky bg-gray-100 top-0 pr-3 pl-4 text-left text-sm  font-bold text-gray-900 sm:pl-6 lg:pl-8"
        >
          Borrower Name
        </th>
        <th
          scope="col"
          class="px-3 min-w-[5rem] py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Borrower Phone
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky min-w-[10rem] bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          BVN
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 min-w-[7rem] sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Carried Forward Previous
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 min-w-[6rem] sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Carried Forward Next
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Date Created
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Date of Incorporation
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Disbursement Method Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Distribution Channel
        </th>

        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Distribution Date
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Interest Rate
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          IS Active
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Business Registered
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Loan amount
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Loan amount Outstanding
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Loan Due Today
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Loan status Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Loan type Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Rc Number
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Referring Officer
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Repayment Fixed Account Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Repayment Fixed Account Number
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Repayment Method Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 sticky bg-gray-100 top-0 text-left text-sm  font-bold text-gray-900"
        >
          Tenor
        </th>
        <th
          scope="col"
          class=" py-3.5 sticky bg-gray-100 top-0 pl-3 pr-4 sm:pr-6"
        >
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody v-if="isLoading">
      <tr class="even:bg-gray-50" v-for="i in 9" :key="i">
        <td
          v-for="j in 9"
          :key="j"
          class="whitespace-nowrap px-3 py-6 text-sm animate-pulse"
        ></td>
      </tr>
    </tbody>
    <tbody
      v-else-if="trackedLoans && trackedLoans.length"
      class="divide-y divide-gray-200 bg-white"
    >
      <tr
        class="even:bg-gray-50"
        v-for="person in trackedLoans"
        :key="person._id"
      >
        <td
          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 capitalize"
        >
          {{ person.borrower_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.borrower_phone || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.bvn || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.carried_forward_from_previous || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium capitalize"
        >
          {{ person.carried_forward_to_next_day }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ moment(person.created_at).format('DD/MMM/YYYY') }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ moment(person.date_of_incorporation).format('DD/MMM/YYYY') }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.disbursement_method_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.distribution_channel || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ moment(person.distribution_date) || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.interest_rate || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.is_active || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.is_business_registered || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.loan_amount || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.loan_amount_outstanding || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.loan_due_to_collect_today || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.loan_status_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.loan_type_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.rc_number || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.referring_officer || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.repayment_fixed_account_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.repayment_fixed_account_number || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.repayment_method_name || 'N/a' }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm  text-gray-800 font-medium"
        >
          {{ person.tenor || 'N/a' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      trackedLoans: [],
      isLoading: false,
      moment,
    };
  },
  methods: {
    getAllTrackedLoans() {
      this.isLoading = true;
      this.$store
        .dispatch('GET_ALL_LOAN_TRACKER')
        .then((res) => {
          this.isLoading = false;
          this.trackedLoans = res.data.item;
          console.log(res.data.item, 'pppo');
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  async created() {
    await this.getAllTrackedLoans();
  },
};
</script>
